













































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Benzene/water', value: 'benzene'}, // Density of 0.876 g/mL
        {text: 'Pentane/water', value: 'pentane'}, // Density of 0.626 g/mL
        {text: 'Dichloromethane/water', value: 'dichloromethane'}, // Density of 1.33 g/mL
        {text: 'Chloroform/water', value: 'chloroform'}, // Density of 1.49 g/mL
        {text: '${n}$-Butanol/water', value: 'nButanol'}, // Density of 0.810 g/mL
      ],
      optionsFr: [
        {text: 'Pentane/eau', value: 'pentane'},
        {text: '${n}$-Butanol/eau', value: 'nButanol'},
        {text: 'Benzène/eau', value: 'benzene'},
        {text: 'Chloroforme/eau', value: 'chloroform'},
        {text: 'Dichlorométhane/eau', value: 'dichloromethane'},
      ],
    };
  },
};
